@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// Sass for videojs-watermark
.video-js {
    &.vjs-watermark {
        display: block;
    }
    
    .vjs-watermark-content {
        opacity: 0.99;
        position: absolute;
        padding: 1rem;
        @include transition(visibility 1s, opacity 1s);

        z-index: 99999;
        pointer-events: none;

        @media screen and (max-width: 768px) {
            @media screen and (orientation: landscape) {
                max-width: 20vw;
            }            
            
            @media screen and (orientation: portrait) {
                max-width: 40vw;
            }
        }

        max-width: 200px;
    }

    // pre-defined positions
    .vjs-watermark-top-right {
        right: 0;
        top: 0;
    }
    .vjs-watermark-top-left {
        left: 0;
        top: 0;
    }
    // bottom set to 30px to account for controlbar
    .vjs-watermark-bottom-right {
        right: 0;
        bottom: 30px;
    }
    .vjs-watermark-bottom-left {
        left: 0;
        bottom: 30px;
    }

    //fade out when the user is not active and the video is playing.
    &.vjs-user-inactive.vjs-playing .vjs-watermark-fade {
        opacity: 0;
    }
}

